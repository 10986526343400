import './App.css';
import { Typography } from '@mui/material';

import Datasets from './Datasets';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <div>
        <img src="/static/images/cadre_logo.png" alt="CADRE Banner" className='App-logo'/>
      </div>
      <div className="App-header">
        <Typography className="App-header" variant="h3">Welcome to the CPS Data Application</Typography>
      </div>
      <div className="App-body">
        <Typography variant="body">
        The Current Population Survey (CPS) is a monthly survey of about 60,000 U.S. households. The survey collects labor
        force characteristics and a variety demographic information from respondents. Designed as a nationally representative 
        survey, the CPS data is the source for many key labor market indicators and the official source of the unemployment
        rate. The CPS is also used to calculate other labor market statistics including: labor force participation, hours worked,
        class of worker, industry, occupation, and reasons for unemployment.
        </Typography>
      </div>
      <div className="App-header2">
        <Typography variant="h4">Datasets</Typography>
      </div>
      <Datasets />
      <Footer />
    </div>
  );
}

export default App;
